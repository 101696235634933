@import './variables';

.pagination {
    display: block;
    padding: 2rem 0;
    text-align: center;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

li.next,
li.previous {
    top: -3px;
}

.pagination > li {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
}

.pagination > li::after {
    display: table;
    clear: both;
    content: ' ';
}

.pagination > li > a,
.pagination > li > span {
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    overflow: hidden;
    padding: 1px;
    line-height: 30px;
    text-align: center;
    border: none;
    background-color: $proLilac;
    color: $proDarkBlue;
    cursor: pointer;
    outline: none;
    position: relative;
    display: block;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    color: white;
    background-color: $proDarkBlue;
}

.pagination .previous > a,
.pagination .next > a {
    text-indent: -9999px;
    background-color: transparent !important;
}

.pagination .previous > a::after,
.pagination .next > a::after {
    text-indent: 0px;
    font-family: $iconFontStack;
    content: '\78';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pagination .next > a::after {
    content: '\79';
}

.pagination .disabled {
    opacity: 0.1;
    cursor: not-allowed;
}

.pagination .disabled a {
    cursor: not-allowed;

    &:hover {
        color: rgba(0,0,0,.8);
    }
}

@media only screen and (max-width: 425px) {
    .pagination > li {
        display: none;
    }

    .pagination .previous,
    .pagination .next {
        display: inline-block;
    }
}
