@import './variables';

.slick-arrow {
    background: none;
    border: 0;
    height: 30px;
    left: -55px;
    opacity: 0.9;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-indent: -9999px;
    top: 90px;
    transition: opacity 0.5s ease, border 0.5s ease;
    width: 20px;

    // &:hover,
    // &:focus {
    //     border: 1px solid #009edb;
    //     opacity: 1;
    // }

    &::before {
        color: $proGreyLightAlt;
        content: 'x';
        display: block;
        font-family: $iconFontStack;
        font-size: $h2Size;
        height: 100%;
        left: 0;
        line-height: $h2Size;
        position: absolute;
        text-align: center;
        text-indent: 0;
        top: 0;
        width: 100%;
    }
}

.slick-next {
    left: auto;
    right: -55px;

    &::before {
        content: 'y';
        text-indent: -1px;
    }
}

.slick-prev {
    &::before {
        content: 'x';
        text-indent: 0;
    }
}

.slick-disabled {
    opacity: 0;
}

/* dots */

.slick-dots {
    list-style: none;
    padding: 0;
    padding-top: 25px;
    text-align: center;

    & > li {
        display: inline-block;
        padding-left: 12px;
        padding-right: 12px;

        & > button {
            background: none;
            border: 1px solid $solBlue !important;
            border-radius: 50%;
            display: block;
            height: 15px;
            overflow: hidden;
            padding: 0;
            text-indent: -9999px;
            width: 15px;

            &:hover {
                background-color: $solBlue;
            }
        }

        &::before {
            display: none !important;
        }
    }

    & > .slick-active {
        & > button {
            &,
            &:hover,
            &:focus {
                background-color: $solBlue !important;
            }
        }
    }
}

.slick-track {
    margin-left: 0;
}

@media only screen and (max-width: 1440px) {
    .slick-arrow {
        left: -25px;
    }

    .slick-next {
        left: auto;
        right: -25px;
    }
}

@media only screen and (max-width: 1200px) {
    .slick-arrow {
        left: -20px;
    }

    .slick-next {
        left: auto;
        right: -20px;
    }
}

@media only screen and (max-width: 992px) {
    .slick-arrow,
    .slick-next {
        display: none !important;
    }

    .slick-dots {
        & > li {
            padding-left: 7px;
            padding-right: 7px;

            & > button {
                width: 15px;
            }
        }
    }
}
