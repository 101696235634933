@import './variables';
@import './functions';

img {
    max-width: 100%;
}

.block-link {
    &,
    &:hover,
    &:focus,
    &:active,
    &:visited {
        display: block;
        text-decoration: none;
    }
}

.mobile-only {
    display: none;
}

.equalize-children {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

.spinner-wrapper {
    background-color: $black;
    background-color: hexToString($black, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.spinner {
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    background-color: #333;
    height: 40px;
    left: 50%;
    margin: 100px auto;
    margin-left: -20px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;
}

.blur-when-loading {
    &.blurred {
        filter: blur(5px);
    }

    &.loading {
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }
}

.search-modal-bg {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;
}

.show-search-modal {
    height: 100vh;
    overflow: hidden;
    width: 100vw;

    .search-modal-bg {
        background-color: hexToString($black, 0.5);
        cursor: pointer;
        height: 100vh;
        left: 0;
        opacity: 1;
        pointer-events: all;
        position: fixed;
        top: 0;
        transition-delay: 0.1s;
        width: 100vw;
        z-index: 3;
    }
}

.ratio-16x9 {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
}

.ratio-1x1 {
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.react-datepicker {
    font-size: $bodySize;
    line-height: $bodyLineHeight;

    //sass-lint:disable-block class-name-format
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: $bodyLineHeight;
        width: 24px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: $bodySize;
        line-height: $bodyLineHeight;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: $proTableDarkBlue;
        color: $white;
    }

    .react-datepicker__header {
        background-color: $proTableLightBlue;
    }

    //sass-lint:enable class-name-format
}

.v-align-content {
    align-content: center;
    display: flex;
}

.show-loading-animation {
    &.rect-shape,
    &.round-shape,
    &.text-row,
    .rect-shape,
    .round-shape,
    .text-row {
        animation: react-placeholder-pulse 1.5s infinite;
    }
}

.react-datepicker-popper {
    z-index: 85 !important;
}

@keyframes react-placeholder-pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@media only screen and (max-width: 768px) {
    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none !important;
    }
}
