@use '../../../sass/variables';

.spinner-wrapper {
    background-color: variables.$black;
    background-color: hexToString(variables.$black, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.spinner {
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    background-color: #333;
    height: 40px;
    left: 50%;
    margin: 100px auto;
    margin-left: -20px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;
}

.blur-when-loading {
    &.blurred {
        filter: blur(5px);
    }

    &.loading {
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }
}
