/* form variables */

$inputFontSize: 16px;
$inputBgColor: rgba(255, 255, 255, 0.6);
$inputColor: #fff;
$inputPlaceholderColor: rgba(255, 255, 255, 0.8);

label {
    font-size: $inputFontSize;
    font-weight: bold;
}

/* animations */

.form-loading {
    &::after {
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;
        width: 0;
    }
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.form-control {
    background-color: $inputBgColor;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: $inputColor;
    font-size: $inputFontSize;
    height: auto;
    padding: 12px 10px;
    transition: none;

    &:focus {
        box-shadow: none;
        outline-color: rgb(59, 153, 252);
        outline-offset: -2px;
        outline-style: auto;
        outline-width: 5px;
    }

    &[disabled] {
        opacity: 0.5;
    }
}

.form-group {
    margin-bottom: 1px;
    position: relative;

    .form-error {
        color: $errorRed;
        display: block;
        min-height: 48px;
        padding-left: 40px;
        position: absolute;
        right: -295px;
        top: 29px;
        width: 280px;

        &::before {
            color: $errorRed;
            content: '\2716';
            display: block;
            font-size: $h1Size;
            font-weight: bold;
            height: $h1Size;
            left: 0;
            line-height: $h1Size;
            margin-top: -(calc($h1Size / 2));
            position: absolute;
            top: 50%;
            width: $h1Size;
        }
    }

    /* validation styles */

    .error {
        &:not(button) {
            border: 2px solid $errorRed !important;

            > .error {
                border-right: 0 !important;
            }
        }
    }

    .success {
        &:not(button) {
            border: 2px solid $successGreen !important;

            > .success {
                border-right: 0 !important;
            }
        }
    }

    /* select */

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0;
        padding-right: 55px;
        z-index: 1;

        option {
            color: $black;
        }
    }

    .select-wrapper {
        position: relative;

        .select-arrow {
            color: $proDarkBlue;
            height: 48px;
            line-height: 15px;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 55px;
            z-index: 2;

            &::before {
                content: '\7a';
            }
        }
    }

    .vat-wrapper {
        position: relative;

        input {
            padding-right: 40px;
        }

        .fa-spinner {
            display: block;
            height: 28px;
            margin-top: -14px;
            position: absolute;
            right: 15px;
            top: 50%;
            width: 28px;
        }
    }
}

.form-group-forgot-password {
    margin-bottom: 0;
    padding-top: 5px;
}

.form-group-submit {
    margin-bottom: 0;
    padding: 25px 0 15px;
}

/* checkbox styles */

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
    margin-bottom: 35px;

    input {
        &[type='radio'],
        &[type='checkbox'] {
            margin-bottom: 0;
            line-height: normal;
            margin-top: 4px;
            margin-left: -20px;
        }
    }
}

.checkbox {
    label {
        display: block;
        padding-left: 30px;
        position: relative;
        cursor: pointer;

        > div {
            position: relative;

            &::after {
                clear: both;
                content: ' ';
                display: table;
            }
        }
    }

    input {
        &[type='checkbox'] {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: $proDarkBlue;
            border: 1px solid $proTextGray;
            color: $lightBlue;
            cursor: pointer;
            height: 20px;
            left: 20px;
            position: absolute;
            text-align: center;
            top: -2px;
            width: 20px;

            &:checked {
                &::after {
                    content: '\2713';
                }
            }
        }
    }
}

.checkbox + .form-error {
    display: none;
}

.input-ghost {
    background-color: transparent;
    border: 1px solid $proGreyLightAlt;
    padding: 10px 8px;
    transition: border 500ms ease;

    &:focus {
        border: 1px solid $white;
    }
}

.input-wrapper-has-icon {
    position: relative;

    &::after {
        content: '';
        display: block;
        font-family: $iconFontStack;
        font-size: $h1Size;
        height: 100%;
        line-height: $h1LineHeight;
        pointer-events: none;
        position: absolute;
        right: 15px;
        text-align: center;
        top: 0;
        width: 50px;
    }

    ::placeholder {
        color: inherit;
        opacity: 0.7;
    }
}

.input-wrapper-has-search-icon {
    &::after {
        content: '\52';
    }
}

.input-has-icon {
    padding-right: 65px;
}

.form-block {
    display: block;
}

::-webkit-input-placeholder {
    color: $inputPlaceholderColor;
}

:-ms-input-placeholder {
    color: $inputPlaceholderColor;
}

::-moz-placeholder {
    color: $inputPlaceholderColor;
}

:-moz-placeholder {
    color: $inputPlaceholderColor;
}

.captcha-wrapper {
    margin-bottom: 25px;
}

@media only screen and (max-width: 992px) {
    .form-group {
        .form-error {
            &::before {
                display: none;
            }
        }
    }
}
