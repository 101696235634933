@use '../../../sass/variables';

.standard-table {
    .table-wrapper {
        padding-top: 35px;
        @media only screen and (max-width: 768px) {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    table {
        width: 100%;

        &.equal-columns {
            table-layout: fixed;
        }

        & > thead {
            & > tr {
                & > th {
                    background-color: variables.$proTableDarkBlue;
                    border-bottom: 2px solid variables.$white;
                    color: variables.$white;
                    font-weight: bold;
                    padding: 10px 15px;
                    vertical-align: middle;
                    border-right: 1px solid variables.$white;
                }
            }
        }

        & > tbody {
            & > tr {
                background-color: variables.$proTableLightBlue;

                &:hover,
                &:focus {
                    background-color: variables.$proTableLightBlueHover;
                    outline: 3px solid variables.$solBlue;
                    outline-offset: -2px;
                }

                & > td {
                    border-right: 1px solid variables.$white;
                    border-top: 1px solid variables.$white;
                    color: variables.$proTableDarkBlue;
                    padding: 10px 15px;

                    &.left-th {
                        background-color: variables.$proTableDarkBlue;
                        border-bottom: 2px solid variables.$white;
                        color: variables.$white;
                        font-weight: bold;
                        padding: 10px 15px;
                    }

                    &:last-of-type {
                        border-right: 0;
                    }
                }
            }
        }
    }
}

.standard-table + .standard-text-no-title,
.standard-table + .text-media-variable-no-title,
.standard-table + .media-text-columns-no-title,
.standard-table + .standard-table-no-title {
    margin-top: -50px;
    padding-top: 0;
}

@media only screen and (max-width: 768px) {
    .standard-table {
        table {
            table-layout: auto;

            &.equal-columns {
                table-layout: auto;
            }

            thead {
                tr {
                    th {
                        text-wrap: nowrap;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        color: #161616;
                    }
                }
            }
        }
    }
}
