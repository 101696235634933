@import '~bootstrap/scss/bootstrap';

@import './components';

@tailwind base;

@tailwind components;

@tailwind utilities;

/* typography */

@import './typography';
