@import './variables';

/* defaults */

.ngp-icon {
    display: inline-block;
    position: relative;

    &::after {
        content: ' ';
        display: block;
        height: 26px;
        width: 26px;
        background-size: 26px;
    }
}

/* user */

.ngp-icon-user {
    display: block;

    &::after {
        background-image: url('../assets/images/icons/user/nav_account.png');
    }
}

.ngp-icon-search {
    &::after {
        background-image: url('../assets/images/icons/search/nav_search.png');
    }
}

.ngp-icon-close {
    &::after {
        background-image: url('../assets/images/icons/close/full_nav_close.png');
    }
}

.svg {
    .ngp-icon-user {
        display: block;
        &::after {
            background-image: url('../assets/images/icons/user/nav_account.svg');
        }
    }

    .ngp-icon-search {
        &::after {
            background-image: url('../assets/images/icons/search/nav_search.svg');
        }
    }

    .ngp-icon-close {
        &::after {
            background-image: url('../assets/images/icons/close/full_nav_close.svg');
        }
    }
}

.ngp-icon-nudge-top-1 {
    margin-top: -3px;
}

/* animation modifies */

.ngp-icon-rotate-left-45 {
    &::after {
        transform: rotate(0deg);
        transition: transform 0.5s ease;
    }

    &:hover {
        &::after {
            transform: rotate(-90deg);
        }
    }
}

/* sony NGP / SFI */

[data-icon] {
    &::before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: attr(data-icon);
        font-family: $iconFontStack;
        font-style: normal !important;
        font-variant: normal !important;
        font-weight: normal !important;
        line-height: 1;
        text-transform: none !important;
    }
}

[class^='icon-'],
[class*=' icon-'] {
    &::before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-family: $iconFontStack;
        font-style: normal !important;
        font-variant: normal !important;
        font-weight: normal !important;
        line-height: 1;
        text-transform: none !important;
    }
}

.icon-full-nav-icons-visualisation {
    &::before {
        content: '\61';
    }
}

.icon-full-nav-icons-4k {
    &::before {
        content: '\62';
    }
}

.icon-full-nav-icons-4k-cinema {
    &::before {
        content: '\63';
    }
}

.icon-full-nav-icons-archive {
    &::before {
        content: '\64';
    }
}

.icon-full-nav-icons-audio {
    &::before {
        content: '\65';
    }
}

.icon-full-nav-icons-broadcast-av {
    &::before {
        content: '\66';
    }
}

.icon-full-nav-icons-broadcast-prod {
    &::before {
        content: '\67';
    }
}

.icon-full-nav-icons-ip-live {
    &::before {
        content: '\68';
    }
}

.icon-full-nav-icons-laser {
    &::before {
        content: '\69';
    }
}

.icon-full-nav-icons-medical {
    &::before {
        content: '\6a';
    }
}

.icon-full-nav-icons-projectors {
    &::before {
        content: '\6b';
    }
}

.icon-full-nav-icons-sports {
    &::before {
        content: '\6c';
    }
}

.icon-full-nav-icons-video-security {
    &::before {
        content: '\6d';
    }
}

.icon-full-nav-icons-cledis {
    &::before {
        content: '\6e';
    }
}

.icon-full-nav-icons-corporate {
    &::before {
        content: '\6f';
    }
}

.icon-full-nav-icons-display {
    &::before {
        content: '\70';
    }
}

.icon-full-nav-icons-education {
    &::before {
        content: '\71';
    }
}

.icon-full-nav-icons-entertainment {
    &::before {
        content: '\72';
    }
}

.icon-full-nav-icons-hdr {
    &::before {
        content: '\73';
    }
}

.icon-full-nav-icons-house-worship {
    &::before {
        content: '\74';
    }
}

.icon-full-nav-icons-vis-comms {
    &::before {
        content: '\75';
    }
}

.icon-full-nav-icons-visualisation-1 {
    &::before {
        content: '\76';
    }
}

.icon-down-arrows {
    &::before {
        content: '\77';
    }
}

.icon-l-arrows-1 {
    &::before {
        content: '\78';
    }
}

.icon-r-arrows {
    &::before {
        content: '\79';
    }
}

.icon-up-arrows {
    &::before {
        content: '\7a';
    }
}

.icon-rd-brochures {
    &::before {
        content: '\41';
    }
}

.icon-rd-generic {
    &::before {
        content: '\42';
    }
}

.icon-rd-knowledge {
    &::before {
        content: '\43';
    }
}

.icon-rd-manuals {
    &::before {
        content: '\44';
    }
}

.icon-rd-software {
    &::before {
        content: '\45';
    }
}

.icon-archiving {
    &::before {
        content: '\46';
    }
}

.icon-contact {
    &::before {
        content: '\47';
    }
}

.icon-financing {
    &::before {
        content: '\48';
    }
}

.icon-get-support {
    &::before {
        content: '\49';
    }
}

.icon-locator {
    &::before {
        content: '\4a';
    }
}

.icon-man {
    &::before {
        content: '\4b';
    }
}

.icon-register {
    &::before {
        content: '\4c';
    }
}

.icon-tailored-support {
    &::before {
        content: '\4d';
    }
}

.icon-training {
    &::before {
        content: '\4e';
    }
}

.icon-warranty {
    &::before {
        content: '\4f';
    }
}

.icon-full-nav-close {
    &::before {
        content: '\50';
    }
}

.icon-nav-account {
    &::before {
        content: '\51';
    }
}

.icon-nav-search {
    &::before {
        content: '\52';
    }
}

.icon-sony-logo-white {
    &::before {
        content: '\53';
    }
}

.icon-quote-left {
    &::before {
        content: '\54';
    }
}

.icon-quote-right {
    &::before {
        content: '\55';
    }
}

/* icon size modifiers */

.icon-2x {
    font-size: $icon2x;
}

.icon-3x {
    font-size: $icon3x;
}

.icon-4x {
    font-size: $icon4x;
}

.icon-5x {
    font-size: $icon5x;
}

.icon-6x {
    font-size: $icon6x;
}

.icon-7x {
    font-size: $icon7x;
}

/* icon hover states */

.icon-rotate-left-90 {
    display: inline-block;
    transform: rotate(0deg);
    transition: transform 0.5s ease;

    &:hover,
    &:focus {
        transform: rotate(-90deg);
    }
}

.icon-red {
    color: $red;
}
