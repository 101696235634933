@import '~slick-carousel/slick/slick';
@import '~sweetalert/dev/sweetalert.scss';
@import '~sweetalert2/src/sweetalert2.scss';

.slick-slide {
    img {
        display: unset;
    }
}

/* functions */

@import './functions';

/* fonts */

@import './fonts';

/* global variables */

@import './variables';

/* helpers */

@import './helpers';

/* forms */

@import './forms';

/* buttons */

@import './buttons';

/* icons */

@import './icons';

/* sliders */

@import './sliders';

/* pagination */

@import './pagination';

/* themes */

@import './themes';

/* videos */

@import './videos';

/* modules */

@import './modules';
@import '../components/modules/StandardTable/StandardTable.scss';
