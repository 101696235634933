@import '../../../sass/variables';

.geo-show {
    display: block;
}

.geo-dismissed {
    display: none;
}

.smort-center {
    display: flex;
    align-items: center;
}

.geo-location {
    background-color: $proTextGray;
    border-bottom: 1px solid $white;
    color: $white;
    padding: 20px 10px;

    .geo-welcome {
        font-family: $headerFontStack;
        font-weight: bold;
        font-size: 16px;
        color: $white;
    }

    .button-group {
        float: right;
    }

    .btn,
    .btn-lg {
        margin: 0 5px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .selector {
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .location-select {
        color: $white;
        font-size: 16px;
        background-color: $gray;
        border: 1px solid $white;
        padding: 12px;
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .geo-location {
        .geo-welcome {
            font-family: $headerFontStack;
            font-size: 16px;
            font-weight: bold;
            color: $white;
            margin: 8% 0;
        }
    }

    .selector {
        margin: 10% 0;
    }

    .button-group {
        width: 100%;
        float: none;

        .btn-lg {
            margin: 5px 0;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .geo-location {
        .geo-welcome {
            font-family: $headerFontStack;
            font-size: 16px;
            font-weight: bold;
            color: $white;
            margin: 13% 0;
        }
    }

    .selector {
        margin: 15% 0;
    }

    .button-group {
        float: none;
    }
}

@media only screen and (max-width: 767px) {
    .smort-center {
        display: block;
    }

    .geo-location {
        .geo-welcome {
            font-family: $headerFontStack;
            font-size: 16px;
            font-weight: bold;
            color: $white;
            margin: 5% 0;
        }

        .selector {
            margin: 5% 0;
        }

        .button-group {
            float: none;
        }
    }
}

@media only screen and (max-width: 425px) {
    .geo-location {
        .geo-welcome {
            font-family: $headerFontStack;
            font-size: 16px;
            font-weight: bold;
            color: $white;
            margin: 5% 0;
        }

        .selector {
            margin: 5% 0;
        }
    }
}
