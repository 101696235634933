@font-face {
    font-family: 'SST W20 Ultra Light';
    src: url('../assets/fonts/SST/SST-W20-Ultra-Light.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Ultra-Light.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light.svg#SST-W20-Ultra-Light') format('svg');
}

@font-face {
    font-family: 'SST W20 Ultra Light Italic';
    src: url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Ultra-Light-Italic.svg#SST-W20-Ultra-Light-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Light';
    src: url('../assets/fonts/SST/SST-W20-Light.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Light.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Light.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Light.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Light.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Light.svg#SST-W20-Light') format('svg');
}

@font-face {
    font-family: 'SST W20 Light Italic';
    src: url('../assets/fonts/SST/SST-W20-Light-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Light-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Light-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Light-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Light-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Light-Italic.svg#SST-W20-Light-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Roman';
    src: url('../assets/fonts/SST/SST-W20-Roman.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Roman.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Roman.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Roman.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Roman.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Roman.svg#SST-W20-Roman') format('svg');
}

@font-face {
    font-family: 'SST W20 Italic';
    src: url('../assets/fonts/SST/SST-W20-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Italic.svg#SST-W20-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Medium';
    src: url('../assets/fonts/SST/SST-W20-Medium.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Medium.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Medium.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Medium.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Medium.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Medium.svg#SST-W20-Medium') format('svg');
}

@font-face {
    font-family: 'SST W20 Medium Italic';
    src: url('../assets/fonts/SST/SST-W20-Medium-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Medium-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Medium-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Medium-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Medium-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Medium-Italic.svg#SST-W20-Medium-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Bold';
    src: url('../assets/fonts/SST/SST-W20-Bold.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Bold.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Bold.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Bold.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Bold.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Bold.svg#SST-W20-Bold') format('svg');
}

@font-face {
    font-family: 'SST W20 Bold Italic';
    src: url('../assets/fonts/SST/SST-W20-Bold-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Bold-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Bold-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Bold-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Bold-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Bold-Italic.svg#SST-W20-Bold-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Heavy';
    src: url('../assets/fonts/SST/SST-W20-Heavy.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Heavy.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Heavy.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Heavy.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Heavy.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Heavy.svg#SST-W20-Heavy') format('svg');
}

@font-face {
    font-family: 'SST W20 Heavy Italic';
    src: url('../assets/fonts/SST/SST-W20-Heavy-Italic.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Heavy-Italic.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Heavy-Italic.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Heavy-Italic.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Heavy-Italic.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Heavy-Italic.svg#SST-W20-Heavy-Italic') format('svg');
}

@font-face {
    font-family: 'SST W20 Condensed';
    src: url('../assets/fonts/SST/SST-W20-Condensed.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Condensed.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Condensed.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Condensed.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Condensed.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Condensed.svg#SST-W20-Condensed') format('svg');
}

@font-face {
    font-family: 'SST W20 Condensed Bd';
    src: url('../assets/fonts/SST/SST-W20-Condensed-Bd.eot?#iefix');
    src: url('../assets/fonts/SST/SST-W20-Condensed-Bd.eot?#iefix') format('eot'),
        url('../assets/fonts/SST/SST-W20-Condensed-Bd.woff2') format('woff2'),
        url('../assets/fonts/SST/SST-W20-Condensed-Bd.woff') format('woff'),
        url('../assets/fonts/SST/SST-W20-Condensed-Bd.ttf') format('truetype'),
        url('../assets/fonts/SST/SST-W20-Condensed-Bd.svg#SST-W20-Condensed-Bd') format('svg');
}

/* icons */

@font-face {
    font-family: 'sonyngp';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/SFI/sony-ngp.eot');
    src: url('../assets/fonts/SFI/sony-ngp.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFI/sony-ngp.woff') format('woff'),
        url('../assets/fonts/SFI/sony-ngp.ttf') format('truetype'),
        url('../assets/fonts/SFI/sony-ngp.svg#sony-ngp') format('svg');
}
