@import './variables';
body {
    color: $bodyColor;
    font-family: $fontStack;
    font-size: $bodySize;
    line-height: $bodyLineHeight;
    position: relative;
}

.text-reduce {
    font-size: $bodyReducedSize;
    line-height: $bodyReducedLineHeight;
}

.header-light {
    font-family: $headerFontStack !important;
}

strong {
    font-family: 'helvetica neue', helvetica, Arial, sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: inherit;
    font-family: $headerBoldFontStack;
    margin-bottom: 15px;
    margin-top: 0;
}

h1,
.h1 {
    font-size: 32px;
    line-height: 36px;
}

h2,
.h2 {
    font-size: 28px;
    line-height: 32px;
}

h3,
.h3 {
    font-size: 24px;
    line-height: 30px;
}

h4,
.h4 {
    font-family: $romanFontStack;
    font-size: 20px;
    line-height: 26px;
}

.h4-bold {
    font-family: $headerBoldFontStack;
}

h5,
.h5 {
    font-family: $headerBoldFontStack;
    font-size: 16px;
    line-height: 24px;
}

h6,
.h6 {
    font-family: $headerBoldFontStack;
    font-size: 14px;
    line-height: 24px;
}

.header-bold {
    font-family: $headerBoldFontStack;
}

/* China font overrides to improve legibility */

.lang-zh {
    font-family: $fontStackCN;

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-family: $headerFontStackCN;
    }
}

a {
    color: $linkColor;

    &:hover,
    &:focus {
        color: $linkColorState;
    }
}

span {
    &[style*='color'] {
        & > a {
            color: inherit;
        }
    }
}

/* inner-content html wrapper */

.inner-content {
    word-wrap: break-word;

    a {
        &:hover,
        &:focus,
        &:active {
            span {
                text-decoration: underline;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 8px;
        margin-top: 20px;
    }

    h6 {
        margin-bottom: 0;
    }

    ul,
    ol {
        counter-reset: li;
        padding-left: 20px;

        li {
            list-style-type: none;
            padding-left: 0;
            position: relative;

            &::before {
                content: '•';
                display: inline-block;
                margin-left: -12px;
                margin-right: 5px;
            }
        }
    }

    ol {
        li {
            &::before {
                content: counter(li) '.';
                counter-increment: li;
                margin-left: -14px;
            }
        }
    }
}

.list-indented {
    padding-left: 10px;

    &.list-indented-0 {
        padding-left: 0;
    }
}

.label-default {
    background-color: $black;
}

.discontinued-label {
    background-color: $proBlueAlt;
    border-radius: 0;
    color: $white;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 5px;
    position: relative;
    text-transform: uppercase;
    top: -1px;

    &.discontinued-pdp {
        font-size: 16px;
        margin-left: 5px;
        top: -7px;
    }

    &.discontinued-search {
        font-size: 14px;
        margin-left: 5px;
        top: -4px;
    }

    &.discontinued-typeahead {
        font-size: 10px;
        letter-spacing: 0.5px;
        padding: 2px 3px;
    }
}
